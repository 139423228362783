import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizGeneralSymptoms({ updateData }) {
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>General Symptoms</h1>

        <div className="">
          <p className=" ">
            <p> Do you experience any of the following general symptoms?</p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Symptoms"
              value="Symptoms"
              onClick={() =>
                updateData(
                  [
                    "Full_Blood_Count_Check",
                    "Iron_Check",
                    "B_Vitamins_Check",
                    "Thyroid_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Symptoms">
              Fatigue
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Insomnia"
              value="Insomnia"
              onClick={() =>
                updateData(
                  [
                    "Thyroid_Check",
                    "Adrenal_Stress_Check",
                    "Cortisol_Blood_Check",
                    "Sleep_Hormone_Check",
                    "Serum_Electrolyte_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Insomnia">
              Insomnia
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Acne"
              value="Acne"
              onClick={() => updateData(["Hormon"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="Acne">
              Acne
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="intolerance"
              value="intolerance"
              onClick={() =>
                updateData(["Hormone_Imbalance_Check", "PCOS_Check"], 3)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="intolerance">
              Dark pigmentation of the skin
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="intolerances"
              value="intolerances"
              onClick={() =>
                updateData(["Thyroid_Check", "Advance_Thyroid_Check"], 0)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="intolerances">
              Temperature intolerance
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="urination"
              value="urination"
              onClick={() =>
                updateData(
                  [
                    "Diabetes_Hba1c_Check",
                    "Serum_Electrolyte_Check",
                    "Kidney_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="urination">
              Frequent urination
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="periods"
              value="periods"
              onClick={() =>
                updateData(
                  [
                    "PCOS_Check",
                    "Adrenals_ACTH_Check",
                    "Hormone_Imbalance_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="periods">
              Irregular periods
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Backpain"
              value="Backpain"
              onClick={() =>
                updateData(["Full_Blood_Count_Check", "Bone_Health_Check"], 3)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Backpain">
              Back pain
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Palpitations"
              value="Palpitations"
              onClick={() =>
                updateData(
                  [
                    "Thyroid_Check",
                    "Adrenal_Stress_Check",
                    "Serum_Electrolyte_Check",
                    "Kidney_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Palpitations">
              Heart Palpitations
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Memoryissues"
              value="Memoryissues"
              onClick={() =>
                updateData(
                  ["Full_Blood_Count_Check", "Health_Man_Woman_Scan"],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Memoryissues">
              Memory issues
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Attention"
              value="Attention"
              onClick={() =>
                updateData(
                  ["Full_Blood_Count_Check", "Health_Man_Woman_Scan"],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Attention">
              Attention span
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Depression"
              value="Depression"
              onClick={() =>
                updateData(
                  ["Full_Blood_Count_Check", "Health_Man_Woman_Scan"],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Depression">
              Depression
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="GSNoneoftheabove"
              value="GSNoneoftheabove"
              onClick={() => updateData(["Nil"], 0)}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="GSNoneoftheabove"
            >
              None of the above
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizGeneralSymptoms;
