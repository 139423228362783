import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ErrorPopup from "../Components/Popups/ErrorPopup";
import cartarrow from "../assets/images/cartarrow.png";
import { CartContext } from "../context/Cart";
import { getRole } from "../helpers/Helpers";
import { Modal } from "react-bootstrap";

const Cart = ({ isVisible, onClose }) => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [text, setText] = useState("");
  const [item, setItem] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setItem(item);
    setShow(true);
  };

  const { cartItems, getCartTotal, getGST, removeFromCart, buildProductExists } = useContext(CartContext);

  const role = getRole();
  const buildProductExistsTrue = buildProductExists();
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (!role) {
      navigate("/register");
      
      
    } else {
      if (role?.role == 3) {
        navigate("/checkout");
      } else {
        setText("You must login as customer to checkout");
        setShow1(true);
      }
    }
  };

  const handleCartClose = () => {
    // setCartVisible(false);
    onClose();
  };

  // useEffect(() => {
  //   const handleDocumentClick = (event) => {
  //     // Check if the cart is visible and if the click is outside the cart
  //     if (
  //       isVisible &&
  //       !document.getElementById("cart").contains(event.target)
  //     ) {
  //       onClose();
  //     }
  //   };

  //   // Add event listener when the component mounts
  //   document.body.addEventListener("click", handleDocumentClick);

  //   // Cleanup: Remove event listener when the component unmounts
  //   return () => {
  //     document.body.removeEventListener("click", handleDocumentClick);
  //   };
  // }, [isVisible, onClose]);

  return (
    <div>
      {isVisible && (
        <div className="cartbox" id="cart">
          {/* <span>
            <img src={cartarrow} alt="" />
          </span> */}
          <i
            onClick={handleCartClose}
            className="fa-solid fa-xmark huz-cncl-mark"
            style={{ backgroundColor: "var(--Slate)", cursor: "pointer" }}
          ></i>
          <h4 className="summry-title">Your Cart ({cartItems?.length})</h4>
          <div className="putnum-parent mt-5">
            {cartItems?.map((item) => (
              <div
                className="putnum"
                style={{ borderBottom: "2px solid var(--Slate)" }}
              >
                <div>
                  <p>{item?.name}</p>
                  <div>
                    <p>
                      {item?.quantity} x{" "}
                      {parseFloat(item?.salePrice) > 0
                        ? parseFloat(item?.salePrice).toFixed(2)
                        : parseFloat(item?.regularPrice).toFixed(2)}{" "}
                      <br />{" "}
                      <span>
                        {" "}
                        {parseFloat(item?.salePrice) > 0
                          ? (
                            parseFloat(item?.salePrice) * item?.quantity
                          ).toFixed(2)
                          : (
                            parseFloat(item?.regularPrice) * item?.quantity
                          ).toFixed(2)}{" "}
                      </span>{" "}
                    </p>
                  </div>
                </div>
                <div onClick={() => handleShow(item)}>
                  <i
                    class="fa-solid fa-trash"
                    style={{ color: "var(--Slate)", cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            ))}
          </div>

          <div className="order-sumry">
            <h4 className="summry-title">Order Summary</h4>
            <div className="d-flex justify-content-between summry-price-edit">
              <p>Price:</p>
              <span>${parseFloat(getCartTotal()).toFixed(2)}</span>
            </div>
            <div className="d-flex justify-content-between summry-price-edit">
              <p>GST:</p>
              <span>${parseFloat(getGST()).toFixed(2)}</span>
            </div>

            {buildProductExistsTrue ? <>
              <div className="d-flex justify-content-between summry-price-edit">
              <p>Collection Fee:</p>
              <span>${20}</span>
            </div>

                    </> : <></>}
            
            <div className="putnum p-0">
              <div className="total-am-dollar">
                <p>
                  Total
                  <p> {`${(buildProductExistsTrue ? parseFloat(getCartTotal() + getGST()) + 20 : parseFloat(getCartTotal() + getGST())).toFixed(2)}` }</p>
                </p>
              </div>
            </div>

            <div className=" mt-4">
              <div className="d-flex justify-content-center mt-2">
                <button
                  onClick={handleNavigate}
                  className={`viewdemo viewtest ${cartItems?.length === 0 ? "cursor-not-allowed" : ""
                    }`}
                  disabled={cartItems?.length === 0}
                  title={
                    cartItems?.length === 0 ? "Kindly add item" : "Checkout"
                  }
                >
                  <span className=""> Checkout</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {show1 && (
        <ErrorPopup
          handleShow={setShow1}
          text={text}
          setText={setText}
          status={false}
        />
      )}

<Modal
  className="huz-modal logoutmodal"
  show={show}
  onHide={() => {
    // Check if the click event originated from inside the modal body
    if (!document.getElementById("modal-content").contains(event.target)) {
      handleClose();
    }
  }}
>      <Modal.Body onClick={(e) => e.stopPropagation()}>
          <i
            className="fa-solid fa-xmark huz-cncl-mark"
            style={{ background: "#F53546" }}
            onClick={handleClose}
          ></i>
          <h1 className="web-prdct-text text-center">{item?.name}</h1>
          <p className="web-prdct-para">
            Are you sure you want to remove this item?
          </p>
        </Modal.Body>
        <Modal.Footer>
          {/* <Link  className="check-no-btn checkout-btn" to="" onClick={handleClose}>
            No
          </Link>
          <Link
            className="checkout-btn"
            to=""
            onClick={() => {
              removeFromCart(item);
              handleClose();
            }}
          >
            Yes
          </Link> */}
          <Link className="checkout-btn" to="" onClick={handleClose}>
            No
          </Link>
          <button
            onClick={() => {
              removeFromCart(item);
              handleClose();
            }}
            className="viewdemo viewtest"
          >
            <span className=""> Yes</span>
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Cart;
