import React, { useState } from "react";
import { Modal } from "react-bootstrap";
function ErrorPopup({ handleShow, text, setText, status }) {
  const [show, setShow] = useState(true);
  
  const handleClose = () => {
    handleShow(false);
    setText("");
  };

  return (
    <div>
      <Modal
        className="logoutmodal delet-modale error-dynamic-modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="p-2">
          <h1>{text}</h1>
          <i className="fa-solid fa-xmark close-mod" onClick={handleClose}></i>
        </Modal.Body>
        <Modal.Footer>
          {status ? (
            <button
              to=""
              style={{ backgroundColor: "#4f6367" }}
              onClick={handleClose}
            >
              Proceed
            </button>
          ) : (
            <button
              to=""
              onClick={handleClose}
              style={{ backgroundColor: "#4f6367" }}
            >
              Close
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ErrorPopup;
