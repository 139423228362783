import { createSlice } from "@reduxjs/toolkit";

const GraphRecordSlice = createSlice({
  name: "GRAPH_RECORD",
  initialState: {
    monthlySalesAnalytics: [],
    monthlyProducts: [],
    monthlyPeopleJoining: [],
    graphError: null,
  },
  reducers: {
    setGraphRecord: (state, action) => {
      let data = action.payload;
      state.monthlySalesAnalytics = data?.orderandcustermcount;
      state.monthlyProducts = data?.productsCount;
      state.monthlyPeopleJoining = data?.countsPractitionerAndCustomerSource;
    },
  },
});

export const { setGraphRecord } = GraphRecordSlice.actions;
export default GraphRecordSlice.reducer;
