import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getCookie } from "../helpers/Helpers";
import Loader from "../Components/Loader";
function AdminProtectedRoutes(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check for token and isAdmin here
    const value = getCookie();
    if (value) {
      const payload = value.split(".");
      const payloadValue = JSON.parse(atob(payload[1]));
      let status = false;

      if (payloadValue.role === "1" || payloadValue.role === "4") {
        status = true;
      }

      props.setAdmin(status);
      props.setToken(value);
    }
    setIsLoading(false); // Set loading to false after checking
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return props.token && props.isAdmin ? (
    <Outlet />
  ) : (
    <Navigate to="/admin-login" />
  );
}

export default AdminProtectedRoutes;
