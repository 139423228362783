// created at 03-01-2024
import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "CURRENT_USER",
  initialState: {
    currentUser: false,
    customerPercentages: {},
    profileUpdated: false,
    userLoader: true,
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
      state.userLoader = false;
    },
    customerPercentagesAction: (state, action) => {
      state.customerPercentages = action.payload;
    },
    customerProfileUpdated: (state, action) => {
      state.profileUpdated = !state.profileUpdated;
    },
  },
});

export const {
  setCurrentUser,
  customerPercentagesAction,
  customerProfileUpdated,
} = userSlice.actions;
export default userSlice.reducer;
