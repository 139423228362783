import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizMaleFertility({ data, setData }) {
  const navigate = useNavigate();
  const goToRecommendation = (name) => {
    let newName = data?.gender + name;

    navigate("/RecomendedTest", {
      state: { testName: newName },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1> Male Fertility</h1>
        <div className="">
          <p className=" ">
            <p>
              Fertility concerns can arise from many different aspects of
              reproductive health. Which of the following areas of fertility
              would you like to address?
            </p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="fmLowEnergy"
              value="fmLowEnergy"
              onChange={() => goToRecommendation("fertilityLibidio")}
            />
            <label className="btn btn-outline-primary" htmlFor="fmLowEnergy">
              Low libido
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="fmDysfunction"
              value="fmDysfunction"
              onChange={() => goToRecommendation("fertilityerectile")}
            />
            <label className="btn btn-outline-primary" htmlFor="fmDysfunction">
              Erectile Dysfunction
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="fmTestlevels"
              value="fmTestlevels"
              onChange={() => goToRecommendation("fertilityfertility")}
            />
            <label className="btn btn-outline-primary" htmlFor="fmTestlevels">
              Fertility & Kids Planning
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="fmHairLoss"
              value="fmHairLoss"
              onChange={() => goToRecommendation("fertilitySTD")}
            />
            <label
              className="btn btn-outline-primary text-truncate cust-text-truncate"
              htmlFor="fmHairLoss"
            >
              STD Check
            </label>
          </div>
          {/* NEW CATEGORY */}
          <div>
            <input
              type="radio"
              className="btn-check"
              name="repro"
              id="repro"
              value="repro"
              onChange={() => goToRecommendation("fertilityGeneral")}
            />
            <label
              className="btn btn-outline-primary text-truncate cust-text-truncate"
              htmlFor="repro"
            >
              General Reproductive Checks
            </label>
          </div>
          {/* NEW CATEGORY */}
        </div>
      </div>
    </div>
  );
}

export default QuizMaleFertility;
