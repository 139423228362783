import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import img1 from "../assets/images/testincludeicon.png";
import axios from 'axios';
import { getCookie } from '../helpers/Helpers';

function HomeSearch({ classes }) {
    const dropdownRef = useRef(null);

    const [openList, setOpenList] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [allProd, setAllProd] = useState([]);
    const [filteredProd, setFilteredProd] = useState([]);
    const [searchVal, setSearchVal] = useState('');

    const handleOpenList = () => {
        if (searchVal !== '') {
            setOpenList(true);
        }
    };

    const handleCloseList = () => {
        setOpenList(false);
    };

    const handleSearchChnage = (text) => {
        setSearchVal(text);
        if (text && text.length > 0 && text !== ' ') {
            const debounceTimer = setTimeout(() => {
                const data = allProd.filter(item => (
                    item.name.toLowerCase().includes(text.toLowerCase())
                ));
                setFilteredProd(data);
                setOpenList(true);
            }, 1500);
            return () => clearTimeout(debounceTimer);
        }
        else {
            setSearchVal('');
            setOpenList(false);
            setFilteredProd([]);
        }
    };

    useEffect(() => {
        const handleOverlayClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                handleCloseList();
            }
        };

        document.addEventListener('click', handleOverlayClick);

        return () => {
            document.removeEventListener('click', handleOverlayClick);
        };
    }, []);

    useEffect(() => {
        (async () => {
            setIsLoader(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/main/get`);
                setAllProd(response?.data?.data);
            }
            catch (err) {
                console.error('ERROR', err);
            }
            setIsLoader(false);
        })();
    }, []);

    return (
        <div className={`${classes} main-home-search`} ref={dropdownRef}>
            <button className='btn-for-search' onClick={handleOpenList}>
                <i className="fa-solid fa-magnifying-glass magnifying-glass-home"></i>
                <input value={searchVal} onChange={(e) => handleSearchChnage(e.target.value)} type="text" placeholder="Search For a Test" />
            </button>
            {openList && (
                <div className='dropdown-list-search'>
                    {filteredProd && filteredProd.length > 0
                        ?
                        filteredProd.map(item => (
                            <Link key={item.id} to='/ProductInner' state={item} className="drop-down-inner-items">
                                <img className='dropdown-search-img' src={img1} alt="Item 1" />
                                <span>{item?.name}</span>
                            </Link>
                        ))
                        :
                        <h6 className='mb-0' style={{ textAlign: "center" }}>No Test</h6>
                    }
                </div>
            )}
        </div>
    );
};

export default HomeSearch;
