import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import deletimg from "../../assets/images/delet-img-icon.png";
function Deletepopup({ handleShow, text, id, handleDelete }) {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    handleShow(false);
  };

  return (
    <div>
      <Modal
        className="logoutmodal delet-modale"
        show={show}
        onHide={handleClose}
      >
        <Modal.Body>
          <img src={deletimg} alt="" className="pb-2" />
          <h1>Do you want to delete this {text}?</h1>
          <i className="fa-solid fa-xmark close-mod" onClick={handleClose}></i>
        </Modal.Body>
        <Modal.Footer>
          <button className="color-set-modal" onClick={handleClose}>
            Close
          </button>

          <button className="delete-modal-btn" onClick={() => handleDelete(id)}>Delete</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Deletepopup;
