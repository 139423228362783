import { useState, useEffect } from "react";
import { getCookie } from "../helpers/Helpers";

const GetFetch = (url) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const abortCont = new AbortController();

    const token = getCookie();

    fetch(url, {
      signal: abortCont.signal,
      headers: {
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch the data for that resource");
        }
        return res.json();
      })
      .then((data) => {
        setIsPending(false);
        setData(data);
        setError(null);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
        } else {
          // Auto catches network / connection error
          setIsPending(false);
          setError(err.message);
        }
        if (err?.response?.status === 401) {
          alert("Unauthorized access");
        }
      });

    // Abort the fetch
    return () => abortCont.abort();
  }, [url, value]);

  const refetch = () => {
    setValue(value + 1);
  };

  return { data, isPending, error, refetch };
};

export default GetFetch;
