import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizSmoking({ updateData }) {
  const goToRecommendation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    navigate("/RecomendedTest", {
      state: { quizStep: activeStep, testName: "Comprehensive T" },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Lifestyle</h1>

        <div className="">
          <p className=" ">
            <p>
              Sharing your smoking status is crucial to assess health risks
              provide tailored advice and determine necessary interventions for
              personalised care.
            </p>
          </p>
        </div>
        <label>
          <h2 style={{ color: "var(--Slate", fontWeight: "500" }}>Smoking</h2>
        </label>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Smoker"
              value="Smoker"
              onClick={() =>
                updateData(["Cardiovascular_Check_Heart_Health"], 1)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Smoker">
              Smoker
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Non"
              value="Non"
              onClick={() =>
                updateData(["Cardiovascular_Check_Heart_Health"], 0)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Non">
              Non-smoker
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizSmoking;
