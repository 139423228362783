import Cookies from "js-cookie";
import { useNavigate, Navigate } from "react-router-dom";

export default function formatDate(timestamp) {
  const dateObj = new Date(timestamp);

  const day = dateObj.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
    dateObj
  );
  const year = dateObj.getFullYear();

  return `${day}-${month}-${year}`;
}

export function getAgeGroup(dateString) {
  const birthYear = new Date(dateString).getFullYear();
  const currentYear = new Date().getFullYear();
  const age = currentYear - birthYear;

  if (age >= 0 && age <= 40) {
    return 1; // Age between 18 and 40
  } else if (age >= 41 && age <= 50) {
    return 2; // Age between 41 and 50
  } else {
    return 3; // Age 50+
  }
}

export function getAgeGroup2(dateString) {
  const birthYear = new Date(dateString).getFullYear();
  const currentYear = new Date().getFullYear();
  const age = currentYear - birthYear;

  if (age >= 0 && age <= 18) {
    return 1; // Age between 18 and 40
  } else if (age >= 19 && age <= 49) {
    return 2; // Age between 41 and 50
  } else {
    return 3; // Age 50+
  }
}

export function storeCookie(cookie) {
  Cookies.set("_root", cookie, { expires: 1, path: "" });
}

export function getCookie() {
  const cookie = Cookies.get("_root");
  return cookie;
}

export function removeCookie() {
  const value = Cookies.remove("_root", { path: "" });
  return;
}

export function getRole() {
  const value = getCookie();
  if (!value) {
    return;
  }

  const payload = value?.split(".");
  const payloadValue = JSON.parse(atob(payload[1]));

  return payloadValue;
}

export function notificationTimeFormatter(isoDateString) {
  const notificationDate = new Date(isoDateString);
  const currentDate = new Date();
  const timeDifferenceInMilliseconds = currentDate - notificationDate;

  // Convert milliseconds to seconds, minutes, and hours
  const seconds = Math.floor(timeDifferenceInMilliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30); // Approximate months as 30 days
  const years = Math.floor(days / 365); // Approximate years as 365 days

  if (seconds < 60) {
    return `Few seconds ago`;
    // return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  } else if (minutes < 60) {
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else if (days < 30) {
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  } else if (months < 12) {
    return `${months} month${months !== 1 ? "s" : ""} ago`;
  } else {
    return `${years} year${years !== 1 ? "s" : ""} ago`;
  }
}

export function flattenObject(obj) {
  const flattenedObj = {};

  // Helper function to recursively flatten nested objects
  function flatten(obj) {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        // If the value is an object, recursively flatten it
        flatten(obj[key]);
        // Merge the keys of the nested object into the flattened object
        Object.assign(flattenedObj, obj[key]);
        // Remove the nested object from the original object
        delete obj[key];
      }
    }
  }

  // Start flattening from the first level of the object
  flatten(obj);

  // Merge the remaining keys of the original object into the flattened object
  Object.assign(flattenedObj, obj);

  return flattenedObj;
}

export function TruncatedText({ text, maxLength }) {
  return (
    <span>
      {" "}
      {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}{" "}
    </span>
  );
}

export function calculatePercentage(result, ref_min, ref_max) {
  // Ensure the result is within the range
  result = Math.min(Math.max(result, ref_min), ref_max);

  // Calculate the percentage
  const percentage = ((result - ref_min) / (ref_max - ref_min)) * 100;

  return percentage;
}

export function countBiomarkers(testPanels) {
  let biomarkerCount = 0;
  let variable = testPanels.testPanelBiomarkers
    ? "testPanelBiomarkers"
    : "biomarkers";

  if (testPanels && Array.isArray(testPanels)) {
    testPanels.forEach((panel) => {
      if (panel[variable] && Array.isArray(panel[variable])) {
        biomarkerCount += panel[variable].length;
      }
    });
  }
  return biomarkerCount;
}

export function sortRecommendedBiomarkers(mainArray) {
  const sortedArray = mainArray.map((item) => {
    let myItem = { ...item };

    // Count the occurrences of each status
    const statusCounts = {
      red: 0,
      yellow: 0,
      green: 0
    };

    // Count the occurrences of each status within recommendationBiomarkers
    myItem.recommendationBiomarkers.forEach((biomarker) => {
      statusCounts[biomarker.status]++;
    });

    
    // Sort the statuses based on their counts
    myItem.recommendationBiomarkers.sort((a, b) => {
      const countA = statusCounts[a.status];
      const countB = statusCounts[b.status];

      // Sort by descending count
      return countB - countA;
    });

    
    console.log(myItem, "my item")

    return myItem;
  });

  console.log(sortedArray, "SORTED ARRAY");

  return sortedArray;
}


// const secretKey = 78954; // Example secret key
const secretKey = 12050272; // Example secret key

// Function to encrypt the ID
export function encryptId(id) {
  const encryptedId = id * secretKey;
  // return Number(String(encryptedId).padEnd(8, '0'));
  return encryptedId;
}

// Function to decrypt the ID
export const decryptId = (encryptedId) => {
  const decryptedId = encryptedId / secretKey;
  // return Number(String(decryptedId).slice(0, 3)); // Get the first 3 digits
  return decryptedId;
};

export function calculateAge(dateOfBirth) {
  const dob = new Date(dateOfBirth);
  const currentDate = new Date();

  // Calculate the difference in years
  let age = currentDate.getFullYear() - dob.getFullYear();

  // Check if birthday has occurred for the current year
  if (
    currentDate.getMonth() < dob.getMonth() ||
    (currentDate.getMonth() === dob.getMonth() &&
      currentDate.getDate() < dob.getDate())
  ) {
    age--;
  }

  return age;
}

export const utcTimeChange = (time) => {
  const utcTimeString = time;
  const utcTime = new Date(utcTimeString);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };
  const localDateTime = utcTime.toLocaleString("en-US", options);
  return localDateTime;
};

export const timeFormated = (time) => {
  const dateObject = new Date(time);

  const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
  const day = dateObject.getDate();
  const year = dateObject.getFullYear();

  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  // Format the date and time
  return `${month}/${day}/${year}, ${hours}:${minutes}:${seconds}`;
};

export function getAge(birthDate) {
  var today = new Date();
  var birthDate = new Date(birthDate);

  var age = today.getFullYear() - birthDate.getFullYear();
  var monthDiff = today.getMonth() - birthDate.getMonth();

  // Check if the current month and day are before the birth month and day
  // If so, the person hasn't had their birthday yet in the current year
  // So, we subtract one from the age
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export function getAgeCategory(age) {
  if (age >= 0 && age <= 18) {
    return 'children';
  } else if (age >= 19 && age <= 49) {
    return 'adult';
  } else {
    return 'elder';
  }
}
