import React, { createContext, useState, useEffect, useRef } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : []
  );

  const [totalQuantity, setTotalQuantity] = useState(0);

  const addToCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);

    if (isItemInCart) {
      return;
    } else {
      setCartItems([...cartItems, { ...item, quantity: 1 }]);
    }
    setTotalQuantity(totalQuantity + 1);
  };

  const bulkInsert = (itemsToAdd) => {
    const itemsToAddIds = itemsToAdd.map((item) => item.id);

    // Filter out items already in the cart
    const newItems = itemsToAdd.filter(
      (item) => !cartItems.some((cartItem) => cartItem.id === item.id)
    );

    if (newItems.length > 0) {
      // Add new items to cart
      setCartItems((prevCartItems) => [
        ...prevCartItems,
        ...newItems.map((item) => ({ ...item, quantity: 1 })),
      ]);
      setTotalQuantity(
        (prevTotalQuantity) => prevTotalQuantity + newItems.length
      );
    }
  };

  const incrementQuantity = (item) => {
    setCartItems(
      cartItems.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      )
    );
    setTotalQuantity(totalQuantity + 1);
  };

  const decrementQuantity = (item) => {
    const updatedCartItems = cartItems.map((cartItem) =>
      cartItem.id === item.id
        ? { ...cartItem, quantity: cartItem.quantity - 1 }
        : cartItem
    );

    const filteredCartItems = updatedCartItems.filter(
      (cartItem) => cartItem.quantity > 0
    );

    setTotalQuantity(totalQuantity - 1);
    setCartItems(filteredCartItems);
  };

  const removeFromCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === item.id);

    if (isItemInCart.quantity === 1) {
      setCartItems(cartItems.filter((cartItem) => cartItem.id !== item.id));
    } else {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : cartItem
        )
      );
    }
    setTotalQuantity(totalQuantity - 1);
  };

  const getCartTotal = () => {
    const value = cartItems.reduce(
      (total, item) =>
        total +
        (parseFloat(item?.salePrice) > 0
          ? parseFloat(item?.salePrice)
          : item?.regularPrice) *
          item.quantity,
      0
    );

    return value;
  };

  const getGST = () => {
    const totalPrice = getCartTotal();
    const gstAmount = totalPrice * 0.1; // Calculating GST at 10%
    return gstAmount;
  };

  const buildProductExists = () => {
    return cartItems.some((item) => item.id >= 500000);
  };

  const emptyCart = () => {
    localStorage.removeItem("cartItems");
    setCartItems([]);
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    if (cartItems) {
      setCartItems(JSON.parse(cartItems));
    }
  }, []);

  useEffect(() => {
    const newTotalQuantity = cartItems.reduce(
      (total, item) => total + item.quantity,
      0
    );
    setTotalQuantity(newTotalQuantity);
  }, [cartItems]);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        totalQuantity,
        getCartTotal,
        getGST,
        decrementQuantity,
        incrementQuantity,
        emptyCart,
        bulkInsert,
        buildProductExists,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
