import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getTestedBiomarkers = createAsyncThunk(
    "GET_TESTED",
    async ({ token, cId }) => {
        try {
            let config = {
                headers: {
                    Authorization: token,
                },
            };
            const statusOrder = {
                "abnormal": 0,
                "atRisk": 1,
                "desirable": 2
            };
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/recommendation/get-all-biomarkers/${cId}`,
                config);
            let data = response.data?.biomarkers;
            data.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);
            return data;

        } catch (err) {
            throw err
        }
    }
);

const TestedBiomarkers = createSlice({
    name: "TESTED_BIOMARKERS_CUSTOMER",
    initialState: {
        bioData: [],
        loader: false,
        error: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTestedBiomarkers.pending, (state) => {
                state.loader = true;
            })
            .addCase(getTestedBiomarkers.fulfilled, (state, action) => {
                state.loader = false;
                state.error = false;
                state.bioData = action.payload;
            })
            .addCase(getTestedBiomarkers.rejected, (state, action) => {
                state.loader = false;
                state.error = action.error.message;
            });
    }
});

export default TestedBiomarkers.reducer;