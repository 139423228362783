import React, { useState, useEffect } from "react";

// import goldenCircle from "../assets/img/goldenCircle.svg";

const Loader = () => {
  const [loader, setLoader] = useState(true);
  const [zIndex, setZindex] = useState(false);
  // Add a useEffect to setLoader to false after 2 seconds
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoader(false);
  //   }, 3000);

  //   setTimeout(() => {
  //     setZindex(true);
  //   }, 1500);

  //   // Clear the timer when the component unmounts
  //   return () => clearTimeout(timer);
  // }, []);

  // Use CSS to add a fade-out effect
  const loaderStyles = {
    opacity: loader ? 1 : 0,
    transition: "opacity 0.8s ease-in-out",
    zIndex: 9999,
  };
  return (
    <div
      className={`loader vh-100 w-100 d-flex align-items-center justify-content-center position-fixed top-0 z-20 theme-body-bg particles-loader ${zIndex ? "-z-index-1" : ""
        }`}
      style={loaderStyles}
    >

      {/* <img   className="position-absolute spinGolden w-100" src="https://generalwebdevelopment.s3.ap-southeast-2.amazonaws.com/images/bannerhomeleftimg.png" alt="" /> */}
      {/* <img className="w-15 spinGolden" src="https://generalwebdevelopment.s3.ap-southeast-2.amazonaws.com/images/optimalogo.png" /> */}
      <img className="spinGolden" src="https://generalwebdevelopment.s3.ap-southeast-2.amazonaws.com/images/bannerhomeleftimg.png" style={{ maxWidth: '7%' }} />
    </div>
  );
};

export default Loader;
