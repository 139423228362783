// store.js
// created at 03-01-2024
import { configureStore } from "@reduxjs/toolkit";

import CurrentUser from "./reducers/CurrentUser";
import customerDetail from "./reducers/customerDetail";
import PractitionerPending from "./reducers/PractitionerPending";
import GraphRecordSlice from "./reducers/GraphRecord";
import AllProducts from "./reducers/AllProducts";
import PractitionerGetConnectedCustomers from "./reducers/PractitionerGetConnectedCustomers";
import TestedBiomarkers from "./reducers/TestedBiomarkers";

const store = configureStore({
  reducer: {
    currentUser: CurrentUser,
    customerDetail: customerDetail,
    PractitionerPending: PractitionerPending,
    graphRecord: GraphRecordSlice,
    allProducts: AllProducts,
    practCustomers: PractitionerGetConnectedCustomers,
    testedBio: TestedBiomarkers,
  },
});

export default store;
