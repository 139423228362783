import React, { useState, useEffect } from "react";


import "../assets/css/Admin.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Modal from "react-bootstrap/Modal";
import adminicon1 from "../assets/images/adminicon1.svg";
import adminicon3 from "../assets/images/adminicon3.svg";
import adminicon4 from "../assets/images/adminicon4.svg";
import adminicon5 from "../assets/images/adminicon5.svg";
import adminicon6 from "../assets/images/adminicon6.svg";
import adminicon8 from "../assets/images/adminicon8.svg";
import adminicon9 from "../assets/images/adminicon9.svg";
import adminicon10 from "../assets/images/adminicon10.svg";
import adminicon2 from "../assets/images/adminicon2.svg";

import popuplogout from "../assets/images/logout-popup-img.png";
import AdminAvatr from "../assets/images/admin-avatar-sidebar.png";
import iconvicon from "../assets/images/iconvicon.png";
import { getRole, removeCookie } from "../helpers/Helpers";
import { useSelector } from "react-redux";


function Sidebar() {
  const { currentUser } = useSelector((store) => store.currentUser);
  const { unReadPrationarCount } = useSelector(
    (state) => state.PractitionerPending
  );
  const [path, setPath] = useState("");

  const [show, setShow] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const ROLE = getRole()?.role;

  const [productDropShow, setProductDropShow] = useState(false);
  const [blogDropShow, setBlogDropShow] = useState(false);
  const [operatorDropShow, setOperatorDropShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = () => {
    removeCookie();
    window.location.href = "/admin-login";
  };

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const handleProductDropShow = () => {
    if (!productDropShow) {
      setBlogDropShow(false);
      setOperatorDropShow(false);
    }
    setProductDropShow(!productDropShow);
  };
  const handleBlogDropShow = () => {
    if (!blogDropShow) {
      setProductDropShow(false);
      setOperatorDropShow(false);
    }
    setBlogDropShow(!blogDropShow); // Corrected from productDropShow to blogDropShow
  };

  const handleOperatorDropShow = () => {
    if (!operatorDropShow) {
      setProductDropShow(false);
      setBlogDropShow(false);
    }
    setOperatorDropShow(!operatorDropShow);
  };

  const handleItemClick = (path) => {
    navigate(path); // Use navigate for navigation
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <nav className="nav-ham" role="navigation">
        <div className={`sidebaradmin ${isOpen ? "open" : ""}`}>
          <div id="menuToggle" onClick={toggleMenu}>
            <input type="checkbox" checked={isOpen} readOnly />

            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className="my-file-upload">
         
            <img className="py-3" src={AdminAvatr} />
            <p>
              <b>{`${currentUser?.firstName} ${currentUser?.lastName}`} </b>
            </p>
          </div>

          <div className="admin-menus admin-menus-2">
            <ul>
              <li className={path === "/AdminPanel" ? "active" : " "}>
                <img src={adminicon1} />{" "}
                <Link to="/AdminPanel"> Dashboard</Link>
              </li>
              {ROLE == "1" || ROLE == "4" || ROLE == "5" ? (
                <li className={path === "/practitioneradmin" ? "active" : ""}>
                  <img src={adminicon3} />{" "}
                  <Link to="/practitioneradmin">
                    Practitioner
                    {unReadPrationarCount != 0 ? (
                      <span className="pract-count">
                        {unReadPrationarCount}
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
              ) : null}
              <li className={path === "/CustomerRequest" ? "active" : ""}>
                <img src={adminicon4} />{" "}
                <Link to="/CustomerRequest">Customer</Link>
              </li>
              {ROLE == "1" ||
              ROLE == "4" ||
              ROLE == process.env.REACT_APP_PRACTIOTIONER_ID ? (
                <li className={path === "/external-report" ? "active" : ""}>
                  <img src={adminicon4} />{" "}
                  <Link to="/external-report">External Reports</Link>
                </li>
              ) : null}

              {ROLE == "1" || ROLE == "4" || ROLE == "5" ? (
                <>
                  <li className={path === "/OrderList" ? "active" : ""}>
                    <img src={adminicon5} /> <Link to="/OrderList">Orders</Link>
                  </li>

                  <li
                    className={
                      path === "/Biomarkers" ||
                      path === "/AddBiomarker" ||
                      path === "/EditBiomarkers" ||
                      path === "/PreviewBiomarker"
                        ? "active"
                        : ""
                    }
                  >
                    <img src={adminicon6} />{" "}
                    <Link to="/Biomarkers">Biomarkers</Link>
                  </li>

                  <li
                    className={
                      path === "/Recommendation" || path === "" ? "active" : ""
                    }
                  >
                    <img src={adminicon3} />{" "}
                    <Link to="/Recommendation">Recommendation</Link>
                  </li>

                  <li>
                    <img src={adminicon8} />{" "}
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Products"
                      onClick={handleProductDropShow}
                      show={
                        productDropShow ||
                        location.pathname === "/TestType" ||
                        location.pathname === "/Categories" ||
                        location.pathname === "/Buildown" ||
                        location.pathname === "/adminProduct" ||
                        location.pathname === "/addProducts" ||
                        location.pathname === "/BuildownAdd" ||
                        location.pathname === "/addProducts" ||
                        location.pathname === "/editProducts"
                      }
                    >
                      <Dropdown.Item
                        className={
                          location.pathname === "/adminProduct" ||
                          location.pathname === "/addProducts" ||
                          location.pathname === "/editProducts"
                            ? "active"
                            : ""
                        }
                        onClick={() => handleItemClick("/adminProduct")}
                      >
                        All Product
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          location.pathname === "/TestType" ? "active" : ""
                        }
                        onClick={() => handleItemClick("/TestType")}
                      >
                        Test Type
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          location.pathname === "/Categories" ||
                          location.pathname === "/Categories"
                            ? "active"
                            : ""
                        }
                        onClick={() => handleItemClick("/Categories")}
                      >
                        Categories
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          location.pathname === "/Buildown" ||
                          location.pathname === "/BuildownAdd"
                            ? "active"
                            : ""
                        }
                        onClick={() => handleItemClick("/Buildown")}
                      >
                        Build Your Own
                      </Dropdown.Item>
                    </DropdownButton>
                  </li>
                </>
              ) : null}

              <li>
                <img src={adminicon9} />

                <DropdownButton
                  onClick={handleBlogDropShow}
                  show={
                    blogDropShow ||
                    location.pathname === "/AllBlogs" ||
                    location.pathname === "/AddBlogs"
                  }
                  id="dropdown-basic-button"
                  title="Blog"
                >
                  <Dropdown.Item
                    className={
                      location.pathname === "/AllBlogs" ? "active" : ""
                    }
                    onClick={() => handleItemClick("/AllBlogs")}
                  >
                    All Blog
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={
                      location.pathname === "/AddBlogs" ? "active" : ""
                    }
                    onClick={() => handleItemClick("/AddBlogs")}
                  >
                    Add New
                  </Dropdown.Item>
                </DropdownButton>
              </li>

              {ROLE == "1" || ROLE == "4" ? (
                <>
                  <li>
                    <img src={adminicon10} alt="" />

                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Operator"
                      onClick={handleOperatorDropShow}
                      show={
                        operatorDropShow ||
                        location.pathname === "/operator" ||
                        location.pathname === "/AddOperator" ||
                        location.pathname === "/EditOperator"
                      }
                    >
                      <Dropdown.Item
                        className={
                          location.pathname === "/operator" ||
                          location.pathname === "/EditOperator"
                            ? "active"
                            : ""
                        }
                        onClick={() => handleItemClick("/operator")}
                      >
                        All Operator
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          location.pathname === "/AddOperator" ? "active" : ""
                        }
                        onClick={() => handleItemClick("/AddOperator")}
                      >
                        Add New
                      </Dropdown.Item>
                    </DropdownButton>
                  </li>
                  <li className={path === "/Subcriptions" ? "active" : ""}>
                    <img style={{}} src={adminicon2} />{" "}
                    <Link to="/Subcriptions">Manage Subscription</Link>
                  </li>
                </>
              ) : null}
            </ul>
          </div>

          <div className="admin-logout">
            <Link to="" onClick={handleShow}>
              Logout
            </Link>
          </div>
        </div>
      </nav>
      {/* logout modal */}
      <Modal className="logoutmodal" show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="logoutimg">
            <img src={popuplogout} alt="" />
          </div>
          <h1 className="text-center py-3" style={{ color: "var(--Slate)" }}>
            Log out of your <span>account?</span>
          </h1>
        </Modal.Body>
        <Modal.Footer>
          <Link className="cancel-btn" to="" onClick={handleClose}>
            Cancel
          </Link>
          <button className="logut-modal-btn" onClick={handleLogout}>
            Log out
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Sidebar;
