import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizHormonalSymptoms({ updateData }) {
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Hormonal symptoms</h1>

        <div className="">
          <p className=" ">
            <p> Do you experience any of the following general symptoms?</p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="HSExcessive"
              value="HSExcessive"
              onClick={() =>
                updateData(["Thyroid_Check", "Well Woman/Man Check"], 3)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="HSExcessive">
              Excessive Sweating
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="HSIncreased"
              value="HSIncreased"
              onClick={() =>
                updateData(
                  [
                    "Serum_Electrolyte_Check",
                    "Adrenal_Stress_Check",
                    "Kidney_Check",
                    "Thyroid_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="HSIncreased">
              Increased Heart Rate
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="HSDecrease"
              value="HSDecrease"
              onClick={() =>
                updateData(
                  ["Hormone_Check", "Gut_Health_Check", "Thyroid_Check"],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="HSDecrease">
              Decrease in weight
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="HSdryness"
              value="HSdryness"
              onClick={() =>
                updateData(
                  [
                    "Hormone_Imbalance_Check",
                    "Cortisol_Blood_Check",
                    "PCOS_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="HSdryness">
              Severe dryness of the skin
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="HSlifeless"
              value="HSlifeless"
              onClick={() =>
                updateData(
                  ["Health_Man_Woman_Scan", "Hormone_Check", "Thyroid_Check"],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="HSlifeless">
              Dull lifeless hair and hair loss
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="HSSwelling"
              value="HSSwelling"
              onClick={() =>
                updateData(
                  [
                    "Serum_Electrolyte_Check",
                    "Adrenals_ACTH_Check",
                    "Adrenal_Stress_Check",
                    "Cortisol_Blood_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="HSSwelling">
              Swelling of the face and fingers
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="HSIrregular"
              value="HSIrregular"
              onClick={() =>
                updateData(
                  [
                    "Hormone_Imbalance_Check",
                    "PCOS_Check",
                    "Cortisol_Blood_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="HSIrregular">
              Irregular periods
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="HSTrembling"
              value="HSTrembling"
              onClick={() =>
                updateData(
                  [
                    "Serum_Catecholamines",
                    "Thyroid_Check",
                    "Cortisol_Blood_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="HSTrembling">
              Trembling fingers or twitching
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="HSgain"
              value="HSgain"
              onClick={() =>
                updateData(
                  [
                    "Adrenals_ACTH_Check",
                    "PCOS_Check",
                    "Cortisol_Blood_Check",
                    "Thyroid_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="HSgain">
              Excess weight gain
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Cravings"
              value="Cravings"
              onClick={() =>
                updateData(
                  [
                    "Hormone_Imbalance_Check",
                    "Diabetes_Hba1c_Check",
                    "Estrogen_and_Progesterone_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="Cravings">
              Cravings for sweets
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Erectile"
              value="Erectile"
              onClick={() => updateData(["Erectile_Dysfunction_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="Erectile">
              Erectile dysfunction
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="drive"
              value="drive"
              onClick={() => updateData(["Hormone_Imbalance_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="drive">
              Decrease sex drive
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="HSNoneoftheabove"
              value="HSNoneoftheabove"
              onClick={() => updateData(["Nil"], 0)}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="HSNoneoftheabove"
            >
              None of the above
            </label>
          </div>
        </div>

        {/* <div className="quiz-bk-handler">
          <Link to="">
            <button className="quiz-bk-btn">Back</button>
          </Link>
        </div> */}
      </div>
    </div>
  );
}

export default QuizHormonalSymptoms;
