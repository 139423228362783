import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getCookie } from "../helpers/Helpers";
import Loader from "../Components/Loader";

function CustomerProtectedRoutes(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const value = getCookie();
      if (value) {
        const payload = value.split(".");
        const payloadValue = JSON.parse(atob(payload[1]));
        let status = false;

        if (payloadValue.role === "3") {
          status = true;
          props.setCustomer(true);
          props.setToken(value);
        }
      }
      setIsLoading(false);
    };

    checkAuthentication();
  }, []); // Empty dependency array to run the effect only once

  if (isLoading) {
    return <Loader />;
  }

  return props.token && props.isCustomer ? (
    <Outlet />
  ) : (
    <Navigate to="/Login" />
  );
}

export default CustomerProtectedRoutes;
