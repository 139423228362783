// SecondaryProtectedRoutes.js
import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getCookie } from "../helpers/Helpers";
import Loader from "../Components/Loader";

function SecondaryProtectedRoutes(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const value = getCookie();
      if (value) {
        const payload = value.split(".");
        const payloadValue = JSON.parse(atob(payload[1]));
        let status = false;

        if (
          payloadValue.role === "1" ||
          payloadValue.role === "4" ||
          payloadValue.role === "5" ||
          payloadValue.role === process.env.REACT_APP_PRACTIOTIONER_ID
        ) {
          status = true;
        }
        props.setAdmin(status);
        props.setSecAdmin(status);
        props.setToken(value);
      }
      setIsLoading(false);
    };

    checkAuthentication();
  }, []); // Empty dependency array to run the effect only once

  if (isLoading) {
    return <Loader />;
  }

  return props.token && (props.isAdmin || props.isSecAdmin) ? (
    <Outlet />
  ) : (
    <Navigate to="/Login" />
  );
}

export default SecondaryProtectedRoutes;
