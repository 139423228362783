import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizCurrentHealth({ updateData }) {
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Current Health Conditions</h1>

        <div className="">
          <p className=" ">
            <p>Do you experience any of the following conditions currently?</p>
          </p>
        </div>

        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCDiabetes"
              value="CHCDiabetes"
              onClick={() =>
                updateData(
                  ["Diabetes_Hba1c_Check", "Cardiovascular_Check_Heart_Health"],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="CHCDiabetes">
              Diabetes 1/2
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCIBS"
              value="CHCIBS"
              onClick={() =>
                updateData(
                  [
                    "Gut_Inflammation_Check",
                    "Gut_Health_Check",
                    "Autoimmune_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="CHCIBS">
              IBS/ IBD
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCInsomnia"
              value="CHCInsomnia"
              onClick={() =>
                updateData(["Cortisol_Blood_Check", "Adrenal_Stress_Check"], 3)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="CHCInsomnia">
              Insomnia
            </label>
          </div>

          {/* <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCUC"
              value="CHCUC"
            />
            <label className="btn btn-outline-primary" htmlFor="CHCUC">
              UC
            </label>
          </div> */}
          {/* <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCAnemia"
              value="CHCAnemia"
            />
            <label className="btn btn-outline-primary" htmlFor="CHCAnemia">
              Anemia
            </label>
          </div> */}
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCPrediabetes"
              value="CHCPrediabetes"
              onClick={() =>
                updateData(
                  ["Diabetes_Hba1c_Check", "Oral_Glucose_Tolerance_Test"],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="CHCPrediabetes">
              Prediabetes
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCcholesterol"
              value="CHCcholesterol"
              onClick={() =>
                updateData(
                  ["Oxidised_LDL_Check", "Cardiovascular_Check_Heart_Health"],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="CHCcholesterol">
              High cholesterol
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Autoimmunedisorder"
              value="Autoimmunedisorder"
              onClick={() => updateData(["Autoimmune_Check"], 3)}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="Autoimmunedisorder"
            >
              Autoimmune disorder
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCCancer"
              value="CHCCancer"
              onClick={() => updateData(["Full_Blood_Count_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="CHCCancer">
              Cancer
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCChrons"
              value="CHCChrons"
              onClick={() =>
                updateData(
                  [
                    "Autoimmune_Check",
                    "Gut_Health_Check",
                    "Gut_Inflammation_Check",
                    "Full_Blood_Count_Check",
                    "Iron_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="CHCChrons">
              Chrons disaese
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCEating"
              value="CHCEating"
              onClick={() =>
                updateData(
                  [
                    "Nutrition_Micronutrients_Check",
                    "Thyroid_Check",
                    "Iron_Check",
                    "Bone_Health_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="CHCEating">
              Eating disorder
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCdisorder"
              value="CHCdisorder"
              onClick={() =>
                updateData(["Cardiovascular_Check_Heart_Health"], 3)
              }
            />
            <label className="btn btn-outline-primary" htmlFor="CHCdisorder">
              Cardiovascular disease{" "}
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCBlood"
              value="CHCBlood"
              onClick={() =>
                updateData(
                  ["Thyroid_Check", "Adrenal_Stress_Check", "Renal_Check"],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="CHCBlood">
              High blood pressure
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCLowBlood"
              value="CHCLowBlood"
              onClick={() => updateData(["Thyroid_Check"], 3)}
            />
            <label className="btn btn-outline-primary" htmlFor="CHCLowBlood">
              Low blood pressure
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Ulcerativecolitis"
              value="Ulcerativecolitis"
              onClick={() =>
                updateData(
                  [
                    "Gut_Inflammation_Check",
                    "Gut_Health_Check",
                    "Autoimmune_Check",
                    "Iron_Check",
                  ],
                  3
                )
              }
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="Ulcerativecolitis"
            >
              Ulcerativecolitis
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="CHCNone"
              value="CHCNone"
              onClick={() => updateData(["Iron_Check"], 0)}
            />
            <label className="btn btn-outline-primary" htmlFor="CHCNone">
              None of the above
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizCurrentHealth;
