import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizFemaleFertility({ data, setData }) {
  const location = useLocation();
  const navigate = useNavigate();
  const goToRecommendation = (name) => {
    console.log(name, "name");

    let newName = data?.gender + name;

    navigate("/RecomendedTest", {
      state: { testName: newName },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1> Female Fertility</h1>
        <div className="">
          <p className=" ">
            <p>
              Fertility concerns can arise from many different aspects of
              reproductive health. Which of the following areas of fertility
              would you like to address?
            </p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="fmWorried"
              id="fmWorried"
              value="fmWorried"
              onChange={() => goToRecommendation("fertilityPregnancy")}
            />
            <label className="btn btn-outline-primary" htmlFor="fmWorried">
              Pregnancy Check
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="fmLowEnergy"
              value="fmLowEnergy"
              onChange={() => goToRecommendation("fertilityfertility")}
            />
            <label className="btn btn-outline-primary" htmlFor="fmLowEnergy">
              Fertility & Kids Planning
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="fmDysfunction"
              value="fmDysfunction"
              onChange={() => goToRecommendation("fertilitySTD")}
            />
            <label className="btn btn-outline-primary" htmlFor="fmDysfunction">
              STD Check
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="fmTestlevels"
              value="fmTestlevels"
              onChange={() => goToRecommendation("fertilityGeneral")}
            />
            <label className="btn btn-outline-primary" htmlFor="fmTestlevels">
              General Reproductive Checks
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizFemaleFertility;
