import React from "react";
import { Link } from "react-router-dom";
function QuizGender({ data, setData, handleData }) {
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Sex</h1>
        <div className="">
          <p className=" ">
            Biological sex has a large impact on many test results. By knowing
            your sex, we are able to give you accurate results.
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Male"
              value="Male"
              checked={data?.gender === "Male"}
              onClick={(e) => {
                handleData("Male", "gender");
              }}
            />
            <label className="btn btn-outline-primary" htmlFor="Male">
              Male
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="Female"
              value="Female"
              checked={data?.gender === "Female"}
              onClick={(e) => {
                handleData("Female", "gender");
              }}
            />
            <label className="btn btn-outline-primary" htmlFor="Female">
              Female
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizGender;
