import React from "react";
import footerlogo from "../../assets/images/footerlogo.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import HomeSearch from "../HomeSearch";

import { encryptId, getCookie, getRole } from "../../helpers/Helpers";

function Footer() {
  const token = getCookie();
  const role = getRole();
  return (
    <>
      <footer>
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.85 }}
          className="footer"
        >
          <div className="container-1600">
            <div className="main-footer">
              <div className="footer-first-grid">
                <div className="footer-logo">
                  <Link to="/">
                    {" "}
                    <img src={footerlogo} alt="" />
                  </Link>
                </div>
                {/* <div className="inputBox inputBox-2 fo-brdr footer-serch">
                  <i className="fa-solid fa-magnifying-glass"></i>
                  <input type="text" placeholder="Search For a Test" />
                </div> */}
                <HomeSearch classes="inputBox inputBox-2 fo-brdr footer-serch" />
              </div>
              <div className="footer-itms-grid">
                <div className="link">
                  <ul className="links-list">
                    <li>
                      <Link to="/About">About us</Link>
                    </li>

                    <li>
                      <Link to="/Collection">Collection Center</Link>
                    </li>

                    <li>
                      <Link to="/HealthHub">Health Hub</Link>
                    </li>
                    <li>
                      <Link to="/Terms-And-Condition">Terms & Condition</Link>
                    </li>
                  </ul>
                  <div className="vl-fo"></div>
                </div>

                <div className="link link-two">
                  <ul className="links-list">
                    <li>
                      <Link to="/Products">Product</Link>
                    </li>
                    <li>
                      <Link to="/Faqs">FAQs</Link>
                    </li>
                    <li>
                      <Link to="/TeamMember">Our Team</Link>
                    </li>
                    <li>
                      <Link to="/Privacy-And-Policy">Privacy Policy</Link>
                    </li>
                  </ul>
                  <div className="vl-fo"></div>
                </div>
                <div className="social">
                  <div className="d-flex justify-content-start">
                    <div className="link link-two">
                      <ul className="links-list">
                        <li>
                          <Link to="/Contact">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="">Privacy & Terms</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="footer-inner-third">
                    <h4>FOLLOW US</h4>

                    <ul className="React-icn-parents">
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=61554845069196">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/optimastasis/">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>

                      <li>
                        <a href="https://www.linkedin.com/company/optima-stasis">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>

                    {/* <div className="vl-fo"></div> */}
                  </div>
                </div>
                <div className="">
                  <div className="footer-inner-third footer-inner-third-2">
                    {/* <h4>FOLLOW US</h4> */}
                    {/* <FontAwesomeIcon icon={faEnvelope} />
                                            <FontAwesomeIcon icon={faFacebook} /> */}
                    {/* <i className="fa-brands fa-instagram"></i> */}
                    {/* <ul className="React-icn-parents">
                                                <li><a href=""><i className="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-instagram"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-twitter"></i></a></li>

                                                <li><a href=""><i className="fa-brands fa-linkedin-in"></i></a></li>
                                            </ul> */}
                    <div className="d-flex justify-content-center login-pg ">
                      <ul>
                        <li className="mb-4">
                          {token ? (
                            <Link
                              to={
                                role?.role == 1 ||
                                role?.role == 4 ||
                                role?.role == 5 ||
                                role?.role == 6
                                  ? "/adminPanel"
                                  : role?.role == 3
                                  ? "/CustomerDashboard"
                                  : "/practitionerdashboard"
                              }
                              className="login-btn "
                            >
                              Dashboard
                            </Link>
                          ) : (
                            <Link to="/Login" className="login-btn ">
                              Login
                            </Link>
                          )}
                        </li>
                        <li>
                          <Link to="/Faqs" className="viewdemo viewdemo-footer">
                            {" "}
                            <span>Ask a question</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-logo-2">
                <Link to="/">
                  {" "}
                  <img src={footerlogo} alt="" />
                </Link>
              </div>
              <div className="foot-info">
                <p>
                  The service we provide is not intended to be a substitute for
                  medical advice. Always seek the help of your doctor or
                  qualified health professionals with questions you may have
                  regarding your health.{" "}
                </p>
              </div>
              <div className="vl"></div>

              <div className="copyright">
                <span>
                  {" "}
                  Copyright © Optima Stasis 2024 - All Rights Reserved | Powered
                  by{" "}
                  <a
                    href="https://techciaga.com.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="techciaga-gothrough"
                  >
                    <u> TECH CIAGA</u>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </motion.section>
      </footer>
    </>
  );
}

export default Footer;
