import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const customerDetailGlobal = createAsyncThunk(
  "customerAsync",
  async ({ userId, token }) => {
    try {
      let config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/customer/get/${userId}`,
        config
      );
      let data = response?.data?.customer;
      return data;
    } catch (err) {
      throw err;
    }
  }
);

const customerDetailSlice = createSlice({
  name: "Customer_detail",
  initialState: {
    successData: {},
    error: {},
  },
  reducers: {},

  extraReducers: (builder) => {
    builder

      .addCase(customerDetailGlobal.pending, (state) => {})
      .addCase(customerDetailGlobal.fulfilled, (state, action) => {
        // state.status = "succeeded";
        state.successData = action.payload;
      })
      .addCase(customerDetailGlobal.rejected, (state, action) => {
        // state.status = "failed";

        state.error = action.error.message;
      });
  },
});

export default customerDetailSlice.reducer;
