// import React from 'react';
// // import './ObjectViewer.css';

// const ObjectViewer = ({ obj }) => {
//     if (typeof obj !== 'object' || obj === null) {
//         return <div className="object-viewer">Invalid object</div>;
//     }

//     const objectString = JSON.stringify(obj, null, 2);

//     // Apply colors to keys and values
//     const styledObjectString = objectString
//         .replace(/"([^"]+)":/g, '<span class="key">"$1":</span>')
//         .replace(/"([^"]+)"/g, '<span class="string-value">"$1"</span>')
//         .replace(/: (true|false)/g, ': <span class="boolean-value">$1</span>')
//         .replace(/: (\d+(\.\d+)?)/g, ': <span class="number-value">$1</span>');

//     return (
//         <div className="object-viewer">
//             <pre dangerouslySetInnerHTML={{ __html: styledObjectString }}></pre>
//         </div>
//     );
// };

// export default ObjectViewer;

import React, { useState } from "react";
// import './ObjectViewer.css';

const ObjectViewer = ({ obj }) => {
  const [isCopied, setIsCopied] = useState(false);
  if (typeof obj !== "object" || obj === null) {
    return <div className="object-viewer">Invalid object</div>;
  }

  const objectString = JSON.stringify(obj, null, 2);
  // Apply colors to keys and values
  const styledObjectString = objectString
    .replace(/"([^"]+)":/g, '"<span class="key"$1</span>":')
    .replace(/"([^"]+)":/g, '"<span class="string-value">$1</span>":')
    .replace(/: "(true|false)"/g, ': "<span class="boolean-value">$1</span>"')
    .replace(/: "(\d+(\.\d+)?)"/g, ': "<span class="number-value">$1</span>"');

  const copyToClipboard = () => {
    setIsCopied(true);
    const el = document.createElement("textarea");
    el.value = JSON.stringify(obj, null, 2);
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };
  return (
    <div className="object-viewer">
      <button className="copy-button" onClick={copyToClipboard}>
        {isCopied ? (
          <i class="fa-solid fa-check icon"></i>
        ) : (
          <i className="fa-regular fa-copy icon"></i>
        )}
      </button>
      <pre dangerouslySetInnerHTML={{ __html: styledObjectString }}></pre>
    </div>
  );
};

export default ObjectViewer;
