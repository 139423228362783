import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizGut() {
  const location = useLocation();
  const navigate = useNavigate();
  const goToRecommendation = (name) => {
    navigate("/RecomendedTest", {
      state: { testName: name },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Gut & Nutrition </h1>
        <div className="">
          <p className=" ">
            <p>
              Providing us with some information about your nutrition and gut
              health is vital in maintaining your overall wellbeing. Digestion
              can affect nearly every system in your body, including sleep,
              fertility and energy levels. Which area are you interested in
              exploring?
            </p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="GutAbdominal"
              value="GutAbdominal"
              onChange={() => goToRecommendation("abdominal")}
            />
            <label className="btn btn-outline-primary" htmlFor="GutAbdominal">
              Abdominal pain/cramps
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="GutInconsistent"
              value="GutInconsistent"
              onChange={() => goToRecommendation("bowel")}
            />
            <label
              className="btn btn-outline-primary"
              htmlFor="GutInconsistent"
            >
              Inconsistent Bowel Habits
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="GutBloating"
              value="GutBloating"
              onChange={() => goToRecommendation("bloating")}
            />
            <label className="btn btn-outline-primary" htmlFor="GutBloating">
              Bloating / Gas
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="GutFatigue"
              value="GutFatigue"
              onChange={() => goToRecommendation("fatigue")}
            />
            <label className="btn btn-outline-primary" htmlFor="GutFatigue">
              Fatigue / Weight
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="GutConcerned"
              value="GutConcerned"
              onChange={() => goToRecommendation("ulcers")}
            />
            <label className="btn btn-outline-primary" htmlFor="GutConcerned">
              Concerned about ulcers
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="GutScreen"
              value="GutScreen"
              onChange={() => goToRecommendation("bowel cancer")}
            />
            <label className="btn btn-outline-primary" htmlFor="GutScreen">
              Screen for Bowel Cancer
            </label>
          </div>

          {/* New Categories Added */}
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="GutScreen"
              value="GutScreen"
              onChange={() => goToRecommendation("vegetarian")}
            />
            <label className="btn btn-outline-primary" htmlFor="GutScreen">
              Vegetarian
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="GutScreen"
              value="GutScreen"
              onChange={() => goToRecommendation("allergies")}
            />
            <label className="btn btn-outline-primary" htmlFor="GutScreen">
              Intolerance & Allergies
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="GutScreen"
              value="GutScreen"
              onChange={() => goToRecommendation("carb")}
            />
            <label className="btn btn-outline-primary" htmlFor="GutScreen">
              Keto/Paleo/Low Carb Diet
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="GutScreen"
              value="GutScreen"
              onChange={() => goToRecommendation("vitamins")}
            />
            <label className="btn btn-outline-primary" htmlFor="GutScreen">
              Monitor VItamins / Nutrition
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizGut;
