import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function QuizSleeping({ updateData }) {
  const goToRecommendation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    navigate("/RecomendedTest", {
      state: { quizStep: activeStep, testName: "Comprehensive T" },
    });
  };
  return (
    <div className="titleparaquiz text-center">
      <div className="quiz-title-head titleparaquiz">
        <h1>Sleeping</h1>

        <div className="">
          <p className=" ">
            <p>
              {" "}
              Sharing information on your sleeping habits is crucial to assess
              health risks provide tailored advice and determine necessary
              interventions for personalised care.
            </p>
          </p>
        </div>
        <div className="formbtn-quiz d-flex justify-content-center">
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="SPExcessive"
              value="SPExcessive"
              onClick={() =>
                updateData(
                  [
                    "Thyroid_Check",
                    "Sleep_Hormone_Check",
                    "Serum_Electrolyte_Check",
                    "Cortisol_Blood_Check",
                  ],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="SPExcessive">
              {" "}
              5 hours
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="SPIncreased"
              value="SPIncreased"
              onClick={() => updateData(["Nil"], 0)}
            />
            <label className="btn btn-outline-primary" htmlFor="SPIncreased">
              5-7 Hours
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="SpDecrease"
              value="SpDecrease"
              onClick={() => updateData(["Nil"], 0)}
            />
            <label className="btn btn-outline-primary" htmlFor="SpDecrease">
              8-9 Hours
            </label>
          </div>
          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="SPdryness"
              value="SPdryness"
              onClick={() => updateData(["Nil"], 0)}
            />
            <label className="btn btn-outline-primary" htmlFor="SPdryness">
              9-10 Hours
            </label>
          </div>

          <div>
            <input
              type="radio"
              className="btn-check"
              name="referralSource"
              id="SPlifeless"
              value="SPlifeless"
              onClick={() =>
                updateData(
                  ["Full_Blood_Count_Check", "B_Vitamins_Check", "Iron_Check"],
                  3
                )
              }
            />
            <label className="btn btn-outline-primary" htmlFor="SPlifeless">
              10+ Hours
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizSleeping;
